/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

const Section = ({ children, classes, style }) => (
    <section className = {'section ' + classes} style={style}>
        <div className = 'container'>
            {children}
        </div>
    </section>
  )
  
  Section.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string,
    style: PropTypes.string
  }
  
export default Section
