/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

const Columns = ({ children, classes }) => (
    <div className = {'columns ' + classes}>
        {children}
    </div>
  )

  Columns.propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.string
  }

export default Columns
